<template>
    <v-snackbar
            :bottom="snackbar.bottom"
            :color="snackbar.color"
            v-model="snackbar.visible"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiline === false">

        {{ snackbar.text }}

        <template v-slot:action="{ attrs }">
<!--            color="pink"-->
            <v-btn
                    id="btn_snack_close"
                    text
                    v-bind="attrs"
                    @click="closeSnackbar"
            >
                Close
            </v-btn>
        </template>
<!--        <v-btn text dark @click="closeSnackbar">Close</v-btn>-->

    </v-snackbar>

    <!--                <v-snackbar-->
    <!--                        :bottom="true"-->
    <!--                        v-model="successLogin"-->
    <!--                        color="info"-->
    <!--                >-->
    <!--                    Your are Logged In-->

    <!--                    <template v-slot:action="{ attrs }">-->
    <!--                        <v-btn-->
    <!--                                dark-->
    <!--                                text-->
    <!--                                v-bind="attrs"-->
    <!--                                @click="successLogin = false"-->
    <!--                        >-->
    <!--                            Close-->
    <!--                        </v-btn>-->
    <!--                    </template>-->
    <!--                </v-snackbar>-->
</template>

<script>
    import { mapMutations } from "vuex"
    export default {
        computed: {
            snackbar() {
                return this.$store.state.snackbar
            }
        },
        methods: {
            ...mapMutations(["closeSnackbar"])
        }
    }
</script>